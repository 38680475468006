.footer-container {
  background-color: #ffffff;
  color: #000000;
  font-family: 'Courier New', monospace;
  text-align: center;
  padding: 0.8rem 0 0rem 0; /* Reduced bottom padding */
}

.contact-section {
  display: flex;
  justify-content: center; /* Center the contact section */
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 8rem; /* Add padding to the section to create space on the sides */
}

.contact-item {
  text-align: center; /* Align text to the center */
  margin: 0 1rem;
  opacity: 0.4;
}

.share-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem; /* Adjust the gap between icons */
  margin: 0 1rem;
}

.contact-item p,
.contact-item a {
  color: #181818;
  margin: 0;
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline;
}

.icon-grey-transparent {
  color: rgba(128, 128, 128, 0.5); /* Adjust the RGBA values as needed */
  font-size: 1.5em; /* Adjust the size of the icons */
}
