@tailwind base;
@tailwind components;
@tailwind utilities;

.typewriter-container {
  position: absolute;
  height: 150px; /* Adjust the height to match the height of your text */
}

.typewriter-text {
  position: absolute;
  top: 0;
  left: 13%; /* Adjust this value to move the text to the right */
  width: 100%; /* Ensure the width is fixed */
  line-height: 1.3; /* Adjust this value to increase the space between lines */
}

.typewriter-container .Typewriter__wrapper {
  display: inline; /* Ensure the wrapper is inline so that the cursor is at the start */
}

.typewriter-container .Typewriter__cursor {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block; /* Ensure the cursor is inline-block to follow the text */
}

.mission-container {
  padding: 2rem;
}

.mission-title {
  font-size: 4rem; /* Adjust size as needed */
  font-weight: bold;
  margin-bottom: 1rem;
}

.mission-content {
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
  white-space: pre-wrap; /* Preserves line breaks and white space */
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%;
  height: 100%;


  font-family: 'Courier New', monospace;



}

body {
  /* background: linear-gradient(to right, #d4fc79, #96e6a1); /* Example gradient */
  background-size: cover;
  background-attachment: fixed; /* Ensure the background stays fixed */
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(to right, #d4fc79, #96e6a1); /* Same gradient as body */
}

.container {
  max-width: 100vw; /* Ensure container does not exceed viewport width */
  overflow: hidden;
  padding-bottom: 20px; /* Adjust the bottom padding to bring the footer up */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(25px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-on-scroll.start-animation {
  animation: fadeInUp 1.2s ease-in-out forwards;
}

.parent-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
}

.col-span-2 {
  grid-column: span 2;
}

.App {
  background-color: white !important;
}

.bg-gradient-custom {
  background: linear-gradient(to top right, #ffffff 50%, #ffccff 90%, #ff7b00 90%);
}

.custom-border-width {
  border-width: 5px; /* Example for a 3px border width */
}

.transparent-textarea {
  background-color: transparent; /* Make fill invisible */
  color: black; /* Text color */
  width: 100%; /* Full width */
  height: 100px; /* Fixed height */
  padding: 10px; /* Padding inside the text box */
  border: none; /* Make border invisible */
  border-radius: 5px; /* Rounded corners */
  resize: none; /* Disable resizing */
  overflow-y: auto; /* Enable vertical scrolling */
  /* Create a fading effect for text at the top edge */
  mask-image: linear-gradient(to bottom, transparent, black 0%, black 100%, transparent);
}


.markdown-content {
  font-family: 'Courier New', monospace;
  /* Adjust the size as needed */
  /* Add any other font styles you need, such as font-weight, line-height, etc. */
}



.youtube-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  max-width: auto;
  background: #000;
  margin-bottom: 1rem;
  border-radius: 10px;
}

.youtube-wrapper iframe {
  position: relative;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
}
