/* YouTubeEmbed.css */

.youtube-embed-wrapper .youtube-iframe {
    position: relative;
}

.youtube-embed-wrapper .youtube-iframe::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px; /* Adjust this value to make the logo smaller */
    height: 42px; /* Adjust this value to maintain the aspect ratio */
    background: url('https://www.youtube.com/yts/img/yt_1200-vfl4C3T0K.png') no-repeat center center;
    background-size: contain;
    transform: translate(-50%, -50%);
    z-index: 1;
    pointer-events: none; /* Ensures the overlay doesn't block interaction with the video */
}


.custom-react-player .react-player__preview {
    .react-player__shadow {
      display: none !important;
    }
    .react-player__play-icon {
      width: 30px !important; /* Adjust this value to make the play icon smaller */
      height: 30px !important; /* Adjust this value to maintain the aspect ratio */
    }
  }