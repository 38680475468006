/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.dropdown {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>');
  background-repeat: no-repeat;
  background-position: right .5rem center;
  background-size: 1.5em;
  padding-right: 2.5rem; /* Original padding + size of the icon */
}

.video-grid {
  object-fit: cover; /* Ensures the image covers the container */

  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7 equal columns */
  gap: 20px; /* Space between grid items */
  padding: 0px; /* Optional padding around the grid */
}

@media (max-width: 780px) { /* For iPhones */
  .video-grid {
    grid-template-columns: repeat(5, 1fr); /* 3 equal columns */
    gap: 10px; /* Space between grid items */

  }
}


@media (max-width: 480px) { /* For iPhones */
  .video-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
    gap: 10px; /* Space between grid items */

  }
}

.video-item {
  object-fit: cover; /* Ensures the image covers the container */

  background-color: transparent; /* Optional background color */
  border-radius:5px; /* Optional rounded corners */
  overflow: hidden; /* Prevent overflow */
  text-align: center; /* Center text */
  max-width: 100%; /* Ensure it doesn't exceed the container */
  max-height: 100%;
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
}

.password-input {
  margin: 10px 0;
  width: 80%;
  padding: 5px 10px;
  background: rgb(237, 237, 237);
  border-radius: 10px;
}

.popup-content button {
  padding: 5px 20px;
  margin-top: 10px;
  background-color: #bfd7bc;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
}

.popup-content button:active {
  transform: scale(0.95);
}



.rainbow-hint {
  border: 1px solid transparent;
  border-radius: 14px; /* Fully rounded corners */
  padding: 10px 15px;
  background: linear-gradient(to right, rgb(228, 228, 228), rgb(255, 255, 255));
  cursor: pointer;
  transition: border 5s;
  display: block; /* Make the button a block element */
  text-align: center; /* Center the text inside the button */
  /* box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); Add a shadow effect for better visibility */
  
  /* margin-top: 20px; Introduced margin at the top */
  
  animation: rainbow-border 5s infinite;
}
@keyframes rainbow-border {
  0% { border-color: rgba(255, 0, 0, 0); box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); }
  14% { border-color: rgba(255, 165, 0, 0); box-shadow: 0 0 10px rgba(255, 165, 0, 0.5); }
  28% { border-color: rgba(255, 255, 0, 0); box-shadow: 0 0 10px rgba(255, 255, 0, 0.5); }
  42% { border-color: rgba(0, 128, 0, 0); box-shadow: 0 0 10px rgba(0, 128, 0, 0.5); }
  57% { border-color: rgba(0, 0, 255, 0); box-shadow: 0 0 10px rgba(0, 0, 255, 0.5); }
  71% { border-color: rgba(75, 0, 130, 0); box-shadow: 0 0 10px rgba(75, 0, 130, 0.5); }
  85% { border-color: rgba(238, 130, 238, 0); box-shadow: 0 0 10px rgba(238, 130, 238, 0.5); }
  100% { border-color: rgba(255, 0, 0, 0); box-shadow: 0 0 10px rgba(255, 0, 0, 0.5); }
}

.rainbow-hint {
  background-clip: padding-box; /* Prevents the white bar artifact */
}